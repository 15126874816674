<template>
  <div class="footer__wrap">
    <div class="footer__disclaimer">
      Disclaimer
    </div>
    <div class="footer__rights">
      © {{ (new Date()).getFullYear() }}. All rights reserved
    </div>
    <div class="footer__text">
      All content provided herein our website, hyperlinked sites, associated
      applications, forums, blogs, social media accounts and other platforms
      (“Site”) is for your general information only, procured from third party
      sources. No part of the content that we provide constitutes financial
      advice, legal advice or any other form of advice meant for your specific
      reliance for any purpose. Any use or reliance on our content is solely at
      your own risk and discretion. You should conduct your own research,
      review, analyse and verify our content before relying on them. No content
      on our Site is meant to be a solicitation or offer.
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style lang="scss" scoped>
.footer {
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: $color-secondary-text;
  }
  // .title {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   margin-bottom: 25px;
  // }
  &__disclaimer {
    order: 1;
    font-size: $font-size-normal2;
    font-weight: $font-weight-bold;
    margin-bottom: 25px;
    @media (max-width: 767px) {
      margin-bottom: 17px;
    }
  }
  &__rights {
    order: 2;
    font-size: 14px;
    margin-bottom: 25px;
    @media (max-width: 767px) {
      order: 3;
      flex: 1 1 100%;
      text-align: center;
      margin-bottom: 0;
    }
  }
  &__text {
    order: 3;
    flex: 1 1 100%;
    font-size: $font-size-small;
    line-height: $line-height-small;
    @media (max-width: 767px) {
      order: 2;
      margin-bottom: 13px;
    }
  }
}
</style>
