<template>
  <div class="faq__wrap">
    <div class="faq__title">
      <Title title="FAQ" position="left" />
    </div>
    <div class="faq__content">
      <div class="faq__row" v-for="(faq, index) in data" :key="index">
        <div
          class="faq__question"
          @click="toogleRowIndex(index)"
          :class="selectedRowIndex === index && 'faq__question_active'"
        >
          <div class="question__text">
            {{ faq.question }}
          </div>
          <div class="question__arrow">
            <img
              src="@/assets/images/decor/arrow-up.svg"
              v-if="selectedRowIndex === index"
            />
            <img src="@/assets/images/decor/arrow-down.svg" v-else />
          </div>
        </div>
        <div
          :ref="
            (el) => {
              if (el) answersDivs[index] = el;
            }
          "
          :style="
            selectedRowIndex === index
              ? { height: computedHeight[`answer-${index}`] }
              : {}
          "
          class="collapsed"
        >
          <div class="faq__answer">{{ faq.answer }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/additional/Title.vue';

import { ref, reactive, onBeforeUpdate, onMounted } from 'vue';

export default {
  name: 'Faq',
  components: { Title },
  props: {
    data: {
      type: Object,
    },
  },
  setup() {
    onBeforeUpdate(() => {
      answersDivs.value = [];
    });

    onMounted(() => {
      answersDivs.forEach((el, index) => initHeight(el, index));
    });

    let selectedRowIndex = ref(-1);
    const toogleRowIndex = (index) => {
      if (selectedRowIndex.value === index) {
        selectedRowIndex.value = -1;
      } else {
        selectedRowIndex.value = index;
      }
    };

    const computedHeight = ref({});
    const answersDivs = reactive([]);

    const initHeight = (el, index) => {
      el.style.height = 'auto';
      el.style.position = 'absolute';
      el.style.visibility = 'hidden';
      el.style.display = 'block';

      const height = getComputedStyle(el).height;
      computedHeight.value[`answer-${index}`] = height;

      el.style.position = null;
      el.style.visibility = null;
      el.style.display = null;
      el.style.height = 0;
    };

    return { selectedRowIndex, toogleRowIndex, computedHeight, answersDivs };
  },
};
</script>

<style lang="scss" scoped>
.question {
  &__text {
    height: 100%;
    flex: 1 1 100%;
    background: $color-fifth;
    padding: 18px 30px;
    @media (max-width: 767px) {
      padding: 15px;
    }
  }
  &__arrow {
    height: auto;
    width: 65px;
    background: $color-fourth;
    padding: 22px 23px;
    @media (max-width: 767px) {
      padding: 15px 23px;
    }
  }
}

.faq {
  &__title {
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 27px;
    }
  }
  &__content {
    margin-bottom: -10px;
  }
  &__question {
    display: flex;
    height: 65px;
    cursor: pointer;
    margin-bottom: 10px;
    color: $color-fourth;
    font-size: $font-size-normal2;
    font-weight: $font-weight-bold;
    @media (max-width: 767px) {
      font-size: $font-size-small;
      height: auto;
    }
    &:hover {
      opacity: $opacity;
    }
    &_active {
      color: $color-primary-text;
      .question__text {
        background: $color-first;
      }
      .question__arrow {
        background: $color-second;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  &__answer {
    padding: 35px 45px;
  }
}

.collapsed {
  height: 0;
  overflow: hidden;
  transition: 0.3s;
}
</style>
