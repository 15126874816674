<template>
  <div class="citadel__wrap">
    <div class="citadel__title">
      <Title title="About Citadel.one" position="right" />
    </div>
    <div class="citadel__content">
      <div class="citadel__image">
        <img src="~@/assets/images/analytical.png" />
      </div>
      <div class="citadel__article">
        <div class="article__text">
          <p>
            Citadel.one is the next generation non-custodial crypto-asset
            management platform. Users can create public addresses for all
            supported networks with one seed phrase, connect their Ledger or
            Trezor device, or import an address generated by another wallet.<br />
            The analytical dashboard provides relevant information on wallets’
            balances and networks’ main metrics.<br />
            One of the main functions of the Citadel.one platform is
            participation in the PoS consensus — users can stake and delegate
            their assets, claim rewards.
          </p>
          <p>
            Citadel.one users can track rewards, withdrawals, transfers and
            deposits across all supported networks and add comments to these
            transactions.
          </p>
        </div>
        <div class="article__button">
          <Button color="orange" class="btn" href="https://app.citadel.one/" width="175">
            TRY
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/additional/Title.vue';
import Button from '@/components/additional/Button.vue';

export default {
  name: 'Citadel',
  components: { Title, Button },
};
</script>

<style lang="scss" scoped>
.citadel {
  &__title {
    margin-bottom: 85px;
    @media (max-width: 767px) {
      margin-bottom: 27px;
    }
  }
  &__content {
    display: flex;
    align-items: flex-start;
    @media (max-width: 1199px) {
      display: block;
    }
  }
  &__image {
    text-align: center;
    max-width: 542px;
    padding: 0 4%;
    @media (max-width: 1199px) {
      margin: 0 auto 20px;
    }
    @media (max-width: 767px) {
      display: none;
    }
    & img {
      width: 430px;
    }
  }
  &__article {
    width: 58%;
    @media (max-width: 1199px) {
      width: auto;
      max-width: 768px;
      margin: 0 auto;
    }
  }
}

.btn {
  margin-left: auto;
  @media (max-width: 1199px) {
    margin: 0 auto;
  }
}

.article__text {
  margin-bottom: 50px;
  @media (max-width: 767px) {
    margin-bottom: 26px;
  }
}
</style>
