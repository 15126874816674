export default {
	state: {
    iostConfig: {
      net: {
        name: 'IOST',
        secondName: 'IOST',
        coin: 'iost',
        api: 'iost'
      },
      about: {
        icon: require('@/assets/images/nets/iost/icon.svg'),
        title: 'iost',
        socials: [
          {
            name: 'iost.io',
            title: 'iost.io',
            icon: require('@/assets/images/web-icon.svg'),
            href: 'https://iost.io/'
          },
          {
            name: 'twitter',
            title: '@iost_twitter',
            icon: require('@/assets/images/twitter-icon.svg'),
            href: 'https://twitter.com/IOST_Official'
          }
        ],
        descryption: `
        IOST describes itself as an <b>“ultra&#8209fast”</b>, decentralized blockchain network and ecosystem based on the “next&#8209generation” consensus protocol dubbed “proof&#8209of&#8209believability.” One of the biggest challenges that IOST aims to resolve centers on how <b>big companies</b> may not embrace blockchains in a customer&#8209facing environment unless they are <b>scalable</b>. The Internet of Services Token is put forward as a way of tackling this problem. There are <b>many projects</b> built on the IOST network ranging from games and gambling to financial applications.
        `
      },
      buy: {},
      where: {
        isCitadel: true,
        otherExchanges: [
          {
            name: 'binance',
            link: 'https://www.binance.com/en/trade/IOST_USDT'
          },
          {
            name: 'okex',
            link: 'https://www.okex.com/market?product=iost_usdt'
          },
          {
            name: 'upbit',
            link: 'https://upbit.com/exchange?code=CRIX.UPBIT.KRW-IOST'
          }
        ]
      },
      stake: {
        calc: {},
        metrics: [
          {
            name: 'Rewards',
            value: '8.44',
            unit: '%',
            unitPosition: 'after'
          },
          {
            name: 'Inflation',
            value: '2',
            unit: '%',
            unitPosition: 'after'
          },
          {
            name: 'Staking ratio',
            value: '23.7',
            unit: '%',
            unitPosition: 'after'
          }
        ],
      },
      how: {
        video: 'https://www.youtube.com/embed/4RaifT33GJo'
      },
      faqs: [
        {
          question: 'Why rewards differ across validators?',
          answer: 'A part of rewards comes from the Ecosystem Fund and is awarded based on the contribution made by the validator, the higher the contribution - the higher the reward.'
        },
        {
          question: 'How frequently is IOST rewarded?',
          answer: 'Staking rewards are claimed by validators on the behalf of the delegators. Each validator claims rewards with different frequency.'
        },
        {
          question: 'Is there an unbonding period?',
          answer: 'Yes, unbonding period set by IOST Network is 3 days.'
        }
      ],
      learn: {
        title: 'Learn more',
        posts: [
          {
            title: 'Citadel.one overview: IOST',
            img: 'https://miro.medium.com/max/1280/1*s8vYQ3VpK5cnppizsa3Nxg.png',
            href: 'https://medium.com/citadel-one/citadel-overview-iost-e88908eb3b4a',
            description: 'Citadel.one is a non-custodial Proof-of-Stake platform for the management and storage of crypto assets.'
          },
          {
            title: 'Citadel.one Staking Guide: IOST',
            img: 'https://miro.medium.com/max/1280/1*5l0N5ayaIpZIke1nbBgIIg.png',
            href: 'https://medium.com/citadel-one/citadel-one-staking-guide-iost-da43314b94fd',
            description: 'To get started, go on an official website Citadel.one and log in.'
          },
          {
            title: 'The IOST Post #1',
            img: 'https://miro.medium.com/max/1000/0*ZRMTFlPJlwxoxcID',
            href: 'https://medium.com/citadel-one/the-iost-post-1-d24ebaa1f5b1',
            description: 'The IOST Post is a biweekly digest of updates that happened within the IOST...'
          },
          {
            title: 'The IOST Post #2',
            img: 'https://miro.medium.com/max/1280/0*ckEo-8Q9BgPMnWBm',
            href: 'https://medium.com/citadel-one/the-iost-post-2-991f3df83ee3',
            description: 'The IOST Post is a biweekly digest of updates that happened within the IOST'
          }
        ]
      },
    },
  },
	getters: {
    GET_IOST_CONFIG(state) {
      return state.iostConfig
    },
    GET_IOST_POSTS(state) {
      return state.iostConfig.learn.posts
    },
    GET_IOST_REWARDS(state) {
      return state.iostConfig.stake.metrics[0].value
    }
  },
  mutations: {
    SET_IOST_STAKE_METRIC(state, { name, value }) {
      const index = state.iostConfig.stake.metrics.findIndex(
        (metric) => metric.name === name
      );
      return (state.iostConfig.stake.metrics[index].value = value);
    }
  },
  actions: {
    UPDATE_IOST_STAKE_METRICS({ rootGetters, commit }, metrics) {
      metrics.map((metric) => {
        const newValue = +rootGetters[`GET_MARKETCAP`].iost?.[metric.key];
        if (newValue && !Number.isNaN(newValue)) {
          commit('SET_IOST_STAKE_METRIC', {
            name: metric.name,
            value: newValue.toFixed(1),
          });
        }
      });
    },
  },
}
