<template>
  <div class="wrap">
    <div class="content">
      <div class="container" v-if="config">
        <div class="background">
          <img
            class="background__desctop"
            :src="
              require(`@/assets/images/nets/${config.net.name.toLowerCase()}/bg-image-desktop.svg`)
            "
          />
          <img
            class="background__mobile"
            :src="
              require(`@/assets/images/nets/${config.net.name.toLowerCase()}/bg-image-mobile.svg`)
            "
          />
        </div>
        <div class="header">
          <Header />
        </div>
        <div class="about">
          <About v-if="config?.about" :data="config.about" />
        </div>
        <div class="what-to-do">
          <WhatToDo :net="config.net">
            <div class="buy">
              <Buy :net="config.net" />
            </div>
            <div class="where" v-if="config.where">
              <Where :data="config.where" />
            </div>
            <div class="stake" v-if="!loading && config.stake">
              <Stake :data="config.stake" :net="config.net" />
            </div>
            <div class="how" v-if="config.how">
              <How :data="config.how" />
            </div>
          </WhatToDo>
        </div>
        <div class="faq" v-if="config?.faqs">
          <Faq :data="config.faqs" />
        </div>
        <div class="citadel">
          <Citadel />
        </div>
        <div class="learn" v-if="config?.learn">
          <Learn :data="config.learn" :net="config.net" />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="nav">
        <Nav />
      </div>
    </div>
    <div class="footer">
      <div class="container">
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import About from '@/components/About.vue';
import WhatToDo from '@/components/WhatToDo.vue';
import Buy from '@/components/Buy.vue';
import Where from '@/components/Where.vue';
import Stake from '@/components/Stake.vue';
import How from '@/components/How.vue';
import Faq from '@/components/Faq.vue';
import Citadel from '@/components/Citadel.vue';
import Learn from '@/components/Learn.vue';
import Nav from '@/components/Nav.vue';
import Footer from '@/components/Footer.vue';

import { useStore } from 'vuex';
// import { useRoute } from 'vue-router';
import { computed, onMounted, ref } from 'vue';
import { useGtag } from 'vue-gtag-next';

export default {
  name: 'App',
  components: {
    Header,
    About,
    WhatToDo,
    Buy,
    Where,
    Stake,
    How,
    Faq,
    Citadel,
    Learn,
    Nav,
    Footer,
  },
  setup() {
    const loading = ref(true);
    const store = useStore();
    const network = process.env.VUE_APP_NETWORK_NAME || 'iost';
    const { event } = useGtag();
    const api = process.env.VUE_APP_BACKEND_URL;
    const config = computed(() => {
      return store.getters[`GET_${network.toUpperCase()}_CONFIG`];
    });

    if (process.env.VUE_APP_ANALYTICS === 'on') {
      const gTrack = () => {
        event(`view_landing_${network}`, {
          // 'event_category' : 'bbb',
          // 'event_label' : 'ccc'
        });
      };

      gTrack();

      const mixpanel = require('mixpanel-browser');
      mixpanel.init('cfbf971e34397d01fa917cbd4044419d', {
        batch_requests: true,
        api_host: `${api}/webhooks/mixpanel`,
        ignore_dnt: true,
      });
      window.mixpanel = mixpanel;

      /* eslint-disable */
      const getQueryParam = (url, param) => {
        // Expects a raw URL
        param = param.replace(/[[]/, '\[').replace(/[]]/, '\]');
        var regexS = '[\?&]' + param + '=([^&#]*)',
          regex = new RegExp(regexS),
          results = regex.exec(url);
        if (
          results === null ||
          (results && typeof results[1] !== 'string' && results[1].length)
        ) {
          return '';
        } else {
          return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
        }
      };

      const campaignParams = () => {
        var campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(
            ' '
          ),
          kw = '',
          params = {},
          first_params = {};
        var index;
        for (index = 0; index < campaign_keywords.length; ++index) {
          kw = getQueryParam(document.URL, campaign_keywords[index]);
          if (kw.length) {
            params[campaign_keywords[index] + ' [last touch]'] = kw;
          }
        }
        for (index = 0; index < campaign_keywords.length; ++index) {
          kw = getQueryParam(document.URL, campaign_keywords[index]);
          if (kw.length) {
            first_params[campaign_keywords[index] + ' [first touch]'] = kw;
          }
        }
        mixpanel.people.set(params);
        mixpanel.people.set_once(first_params);
        mixpanel.identify ()
        mixpanel.register(params);
      };
      campaignParams();
      /* eslint-enable */

      mixpanel.track(`view landing network`, {
        Network: config.value.net.secondName,
      });
    }

    onMounted(async () => {
      await store.dispatch('PULL_CURRENCY');
      await store.dispatch('PULL_MARKETCAP');
      store.dispatch('PULL_GRAPH_DATA', { net: network });

      store.dispatch(`UPDATE_${network.toUpperCase()}_STAKE_METRICS`, [
        {
          name: 'Rewards',
          key: 'yield',
        },
        {
          name: 'Staking ratio',
          key: 'stakingRate',
        },
        {
          name: 'Inflation',
          key: 'inflation',
        },
      ]);
      loading.value = false;
    });

    return { config, loading };
  },
};
</script>

<style lang="scss">
@import '@/styles/_globals.scss';
@import '@/styles/_reset.scss';
</style>

<style lang="scss" scoped>
.wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1 1 auto;
}

.container {
  position: relative;
  margin: 0 auto;
  max-width: 1170px;
  padding: 0 15px;
}

.background {
  position: absolute;
  left: 290px;
  z-index: -1;
  &__mobile {
    display: none;
  }
  @media (max-width: 767px) {
    &__mobile {
      display: block;
    }
    &__desctop {
      display: none;
    }
    left: auto;
    right: 0;
    & img {
      width: 500px;
      // height: 208px;
    }
  }
  @media (max-width: 560px) {
    & img {
      width: 320px;
    }
    // right: auto;
    // left: 5px;
  }
}

.header {
  padding-top: 66px;
  margin-bottom: 81px;
  @media (max-width: 767px) {
    padding-top: 22px;
    margin-bottom: 157px;
  }
}

.about {
  margin-bottom: 99px;
  @media (max-width: 767px) {
    margin-bottom: 44px;
  }
}

.what-to-do {
  margin-bottom: 101px;
  @media (max-width: 767px) {
    margin-bottom: 50px;
  }
}

.buy {
  margin-bottom: 59px;
  @media (max-width: 767px) {
    margin-bottom: 47px;
  }
}

.where {
  margin-bottom: 120px;
  @media (max-width: 767px) {
    margin-bottom: 48px;
  }
}

.stake {
  margin-bottom: 64px;
  @media (max-width: 767px) {
    margin-bottom: 48px;
  }
}

.faq {
  margin-bottom: 81px;
  @media (max-width: 767px) {
    margin-bottom: 58px;
  }
}

.citadel {
  margin-bottom: 84px;
  @media (max-width: 767px) {
    margin-bottom: 56px;
  }
}

.learn {
  margin-bottom: 70px;
  @media (max-width: 350px) {
    margin-bottom: 31px;
  }
}

.nav {
  margin-bottom: 98px;
  @media (max-width: 767px) {
    margin-bottom: 48px;
  }
}

.footer {
  background-color: $color-secondary-bg;
  padding-top: 42px;
  padding-bottom: 72px;
  @media (max-width: 767px) {
    padding-bottom: 29px;
  }
}
</style>
