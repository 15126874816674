<template>
  <div class="about__wrap">
    <div class="about__title">
      <div class="title__icon">
        <svg width="70px" height="70px">
          <use :xlink:href="`${data.icon}#${data.title}`"></use>
        </svg>
      </div>
      <div class="title__text">
        <div class="text__top">NETWORK</div>
        <div class="text__bottom">{{ data.title }}</div>
      </div>
    </div>
    <div class="about__socials">
      <div
        v-for="(social, index) in data.socials"
        :key="`about__socials-${index}`"
      >
        <a :href="social.href" target="_blank" :title="social.name">
          <div class="socials__item">
            <img :src="social.icon" class="socials__icon" />
            <div class="socials__title">{{ social.title }}</div>
          </div>
        </a>
      </div>
    </div>
    <div class="about__descryption" v-html="data.descryption"></div>
  </div>
</template>

<script>
export default {
  name: 'About',
  props: {
    data: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.about {
  &__title {
    display: flex;
    align-items: flex-start;
    margin-bottom: 52px;
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
  &__socials {
    display: flex;
    align-items: center;
    margin-bottom: 49px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }
  &__descryption {
    max-width: 695px;
  }
}

.title {
  &__icon {
    margin-right: 18px;
    & svg {
      fill: $color-third;
    }
  }

  &__text {
    font-weight: $font-weight-bold;
  }
}

.text {
  &__top {
    font-size: $font-size-medium;
    line-height: calc(#{$font-size-medium} * 0.7);
    color: $color-third;
    margin-bottom: 15px;
    @media (max-width: 767px) {
      font-size: $font-size-normal;
      margin-bottom: 5px;
      margin-top: 10px;
    }
  }
  &__bottom {
    text-transform: uppercase;
    line-height: calc(#{$font-size-title-5} * 0.7);
    font-size: $font-size-title-5;
    @media (max-width: 767px) {
      font-size: $font-size-title-7;
    }
  }
}
.socials {
  &__item {
    display: flex;
    align-items: center;
    margin-right: 51px;
    @media (max-width: 767px) {
      margin-right: 29px;
    }
  }
  &__icon {
    margin-right: 9px;
  }
}
</style>
