export default {
  state: {
    bandConfig: {
      net: {
        name: 'BAND',
        secondName: 'Band',
        coin: 'band',
        api: 'band',
      },
      about: {
        icon: require('@/assets/images/nets/band/icon.svg'),
        title: 'band ',
        socials: [
          {
            name: 'bandprotocol.com',
            title: 'bandprotocol.com',
            icon: require('@/assets/images/web-icon.svg'),
            href: 'https://bandprotocol.com/',
          },
          {
            name: 'twitter',
            title: '@BandProtocol',
            icon: require('@/assets/images/twitter-icon.svg'),
            href: 'https://twitter.com/bandprotocol',
          },
        ],
        descryption: `
        Band Protocol is a cross-chain data oracle platform that can take <b>real-world data</b> and supply it to <b>on-chain applications</b> while also connecting APIs to smart-contracts to facilitate exchanging information between on-chain and off-chain data sources. While supporting trustless executions of arbitrary programs, most existing smart contract platforms lack access to real-world data. This limitation hinders the maximum potential of such contracts. BandChain was created to solve this issue by connecting public blockchains with this real-world, off-chain information. Band Protocol unlocks a range of <b>new use cases</b> for developers to explore by delivering reputable, verifiable real-world data to blockchains. They can now use real-world data as part of their DApps logic, including sports, weather, random numbers, price feed data and more.
        `,
      },
      buy: {},
      where: {
        isCitadel: true,
        otherExchanges: [
          {
            name: 'binance',
            link: 'https://www.binance.com/en/trade/BAND_USDT',
          },
          {
            name: 'okex',
            link: 'https://www.okex.com/market?product=band_usdt',
          },
          {
            name: 'coinbase',
            link: 'https://pro.coinbase.com/trade/BAND-USD',
          },
        ],
      },
      stake: {
        calc: {},
        metrics: [
          {
            name: 'Rewards',
            value: '13.19',
            unit: '%',
            unitPosition: 'after',
          },
          {
            name: 'Inflation',
            value: '2',
            unit: '%',
            unitPosition: 'after',
          },
          {
            name: 'Staking ratio',
            value: '15.17',
            unit: '%',
            unitPosition: 'after',
          },
        ],
      },
      how: {
        video: 'https://www.youtube.com/embed/UIotQ9-SEUo',
      },
      faqs: [
        {
          question: 'What are validator commissions?',
          answer:
            'This is the commission that a validator of your choice charges for performing their validation service. Generally validator costs to maintain high-end infrastructure are relatively high and the validator has to cover operating expenses and provide a strong infrastructure.',
        },
        {
          question: 'What are thew risks of delegating?',
          answer:
            'Validators may be slashed for 2 types of misbehaviour. Soft slashing can happen when a validator has a long period of node downtime (less than ~18 out of 19 hours). As a consequence both the delegator and validator lose 0,01% of their total assets staked and the validator would be jailed for 10 minutes. Hard slashing can happen when a validator double signs a block which would also penalize both the validator and delegator for a total 5% slash on the assets that are staked. In order to prevent this, delegators should research their validators and their infrustructure.',
        },
        {
          question: 'Is there an unbonding period?',
          answer: 'Yes, unbonding period set by Cosmos Network is 21 days.',
        },
      ],
      learn: false,
    },
  },
  getters: {
    GET_BAND_CONFIG(state) {
      return state.bandConfig;
    },
    GET_BAND_REWARDS(state) {
      return state.bandConfig.stake.metrics[0].value;
    },
  },
  mutations: {
    SET_BAND_STAKE_METRIC(state, { name, value }) {
      const index = state.bandConfig.stake.metrics.findIndex(
        (metric) => metric.name === name
      );
      return (state.bandConfig.stake.metrics[index].value = value);
    }
  },
  actions: {
    UPDATE_BAND_STAKE_METRICS({ rootGetters, commit }, metrics) {
      metrics.map((metric) => {
        const newValue = +rootGetters[`GET_MARKETCAP`].band?.[metric.key];
        if (newValue && !Number.isNaN(newValue)) {
          commit('SET_BAND_STAKE_METRIC', {
            name: metric.name,
            value: newValue.toFixed(1),
          });
        }
      });
    },
  },
};
