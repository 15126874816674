export default {
	state: {
    secretConfig: {
      net: {
        name: 'Secret',
        secondName: 'Secret',
        coin: 'scrt',
        api: 'secret'
      },
      about: {
        icon: require('@/assets/images/nets/secret/icon.svg'),
        title: 'secret',
        socials: [
          {
            name: 'scrt.network',
            title: 'scrt.network',
            icon: require('@/assets/images/web-icon.svg'),
            href: 'https://scrt.network'
          },
          {
            name: 'twitter',
            title: '@SecretNetwork',
            icon: require('@/assets/images/twitter-icon.svg'),
            href: 'https://twitter.com/secretnetwork'
          }
        ],
        descryption: `Secret Network is a decentralized network for <b>private and secure</b> computation. Nodes on the network can perform generalizable computations over encrypted data, allowing smart contracts (known as <b>secret contracts</b>) to use private and sensitive data as inputs. Developers can build decentralized, privacy-preserving "Secret Apps" on the network. The Secret Network's privacy functionality is critical for many fields, including decentralized finance, Web3, machine learning, access control, and many more. The secret network already launched a <b>Secret bridge</b> that allows users to convert erc-20 tokens into their secret counterparts and make <b>private transactions</b>. SecretSwap - front-running resistant decentralized exchange has been launched on Secret Network allowing users to swap their tokens privately and at low costs.`
      },
      buy: {},
      where: {
        isCitadel: true,
        otherExchanges: [
          {
            name: 'binance',
            link: 'https://www.binance.com/en/trade/SCRT_BTC'
          },
          {
            name: 'secretswap',
            link: 'https://secretswap.net'
          },
          {
            name: 'hotbit',
            link: 'https://www.hotbit.io/exchange?symbol=SCRT_BTC'
          },
        ]
      },
      stake: {
        calc: {},
        metrics: [
          {
            name: 'Rewards',
            value: '27.61',
            unit: '%',
            unitPosition: 'after'
          },
          {
            name: 'Inflation',
            value: '15',
            unit: '%',
            unitPosition: 'after'
          },
          {
            name: 'Staking ratio',
            value: '41.87',
            unit: '%',
            unitPosition: 'after'
          }
        ],
      },
      how: {
        video: 'https://www.youtube.com/embed/_liZEHgopn0'
      },
      faqs: [
        {
          question: 'What are validator commissions?',
          answer: 'This is the commission that a validator of your choice charges for performing their validation service. Generally validator costs to maintain high-end infrastructure are relatively high and the validator has to cover operating expenses and provide a strong infrastructure.'
        },
        {
          question: 'What are thew risks of delegating?',
          answer: 'Validators may be slashed for 2 types of misbehaviour. Soft slashing can happen when a validator has a long period of node downtime (less than 4 out of 8 hours). As a consequence both the delegator and validator lose 1% of their total assets staked and the validator would be jailed for 10 minutes. Hard slashing can happen when a validator double signs a block which would also penalize both the validator and delegator for a total 5% slash on the assets that are staked. In order to prevent this, delegators should research their validators and their infrustructure.'
        },
        {
          question: 'Is there an unbonding period?',
          answer: 'Yes, unbonding period set by Secret Network is 21 days.'
        }
      ],
      learn: {
        title: 'Learn more',
        posts: [
          {
            title: 'Citadel.one Ledger Support for Secret ($SCRT)',
            img: 'https://miro.medium.com/max/1280/1*TjfheXgYn8huQinslE30zw.jpeg',
            href: 'https://medium.com/citadel-one/citadel-one-ledger-support-for-secret-scrt-28c1b4ed4fa8',
            description: 'Hello, guys! Earlier, $SCRT was integrated into Citadel.one platform, introducing a great variety of features for the holders.'
          },
          {
            title: 'Citadel.one Overview: Secret Network',
            img: 'https://miro.medium.com/max/1280/1*qa2ZCgnBzo-sGEn_6BU8bA.png',
            href: 'https://medium.com/citadel-one/citadel-one-overview-secret-network-1289227545d9',
            description: 'Citadel.one users can create platform-native addresses for all supported networks with one seed phrase.'
          },
          {
            title: 'Important Update for Secret Network Users of Citadel.one',
            img: 'https://miro.medium.com/max/1280/1*BUqBgCKVae80UuDFwBrR2w.png',
            href: 'https://medium.com/citadel-one/important-update-for-secret-network-users-of-citadel-one-6b4eb83a4f9',
            description: 'Follow this guide to update your Secret address private keys:'
          },
          {
            title: 'Citadel.one Staking Guide: Secret (SCRT)',
            img: 'https://miro.medium.com/max/1280/1*7yHIu8vlZJDPfH6VglGTSA.png',
            href: 'https://medium.com/citadel-one/citadel-one-staking-guide-secret-scrt-509faeeba260',
            description: 'Secret Network ($SCRT) has been recently integrated with our platform.'
          }
        ]
      },
    },
  },
	getters: {
    GET_SECRET_CONFIG(state) {
      return state.secretConfig
    },
    GET_SECRET_POSTS(state) {
      return state.secretConfig.learn.posts
    },
    GET_SECRET_REWARDS(state) {
      return state.secretConfig.stake.metrics[0].value
    }
  },
  mutations: {
    SET_SECRET_STAKE_METRIC(state, { name, value }) {
      const index = state.secretConfig.stake.metrics.findIndex(
        (metric) => metric.name === name
      );
      return (state.secretConfig.stake.metrics[index].value = value);
    }
  },
  actions: {
    UPDATE_SECRET_STAKE_METRICS({ rootGetters, commit }, metrics) {
      metrics.map((metric) => {
        const newValue = +rootGetters[`GET_MARKETCAP`].secret?.[metric.key];
        if (newValue && !Number.isNaN(newValue)) {
          commit('SET_SECRET_STAKE_METRIC', {
            name: metric.name,
            value: newValue.toFixed(1),
          });
        }
      });
    },
  },
}
