<template>
  <div class="row">
    <div class="row__title">{{ title }}</div>
    <div class="row__value-net">
      <span>{{ valueNet }}</span> {{ net.toUpperCase() }}
    </div>
    <div class="row__value-usd">
      $ <span>{{ valueUsd }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Buy',
  props: {
    title: {
      type: String,
      default: '',
    },
    net: {
      type: String,
      default: '',
    },
    valueNet: {
      type: String,
      default: '',
    },
    valueUsd: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $color-second;
  padding: 21px 0;
  @media (max-width: 520px) {
    flex-wrap: wrap;
    padding: 10px 0;
  }

  &__title {
    width: 180px;
    color: $color-second;
    font-size: $font-size-normal2;
    @media (max-width: 520px) {
      flex: 1 1 100%;
      margin-bottom: 12px;
      font-size: $font-size-small;
    }
  }
  &__value-net {
    width: 175px;
    color: $color-third;
    font-size: $font-size-medium;
    & span {
      font-weight: $font-weight-bold;
    }
  }
  &__value-usd {
    width: 105px;
    text-align: right;
    font-size: $font-size-medium;
    & span {
      font-weight: $font-weight-bold;
    }
  }
}
</style>
