<template>
  <div class="nav__wrap" v-if="networks">

    <a
      v-for="(net, index) in networks"
      :key="index"
      :href="`https://${ net }.citadel.one`"
      class="nav__link"
      :class="currentNet === net && 'nav__link_active'"
    >
      <svg width="19px" height="19px" class="nav__icon">
        <use
          :xlink:href="require(`@/assets/images/nets/${net}/icon.svg`) + `#${net}`"
        ></use>
      </svg>
      <div class="nav__text">
        {{ net }}
      </div>
    </a>

    <!-- <router-link
      v-for="(net, index) in networks"
      :key="`nav__link-${index}`"
      :to="{ name: net }"
      class="nav__link"
      active-class="nav__link_active"
    >
      <svg width="19px" height="19px" class="nav__icon">
        <use
          :xlink:href="require(`@/assets/images/nets/${net}/icon.svg`) + `#${net}`"
        ></use>
      </svg>
      <div class="nav__text">
        {{ net }}
      </div>
    </router-link> -->
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name: 'Nav',
  setup() {
    const store = useStore();
    const networks = computed(() => store.getters.GET_NETWORKS);
    const currentNet = process.env.VUE_APP_NETWORK_NAME || 'iost'
    return { networks, currentNet };
  },
};
</script>

<style lang="scss" scoped>
.nav {
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    // margin-right: -8px;
    @media (max-width: 350px) {
      justify-content: space-between;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 40px;
    padding: 9px;
    border: 1px solid $color-second;
    border-radius: 4px;
    color: $color-second;
    margin: 0 4px 8px;
    &_active {
      color: $color-primary-text;
      border-color: $color-third;
      .nav__icon {
        fill: $color-third;
      }
    }
    &:hover {
      color: $color-first;
      border-color: $color-first;
      .nav__icon {
        fill: $color-first;
      }
    }
    &:active {
      opacity: $opacity;
    }
  }
  &__icon {
    fill: $color-second;
    margin-right: 13px;
  }
  &__text {
    font-size: $font-size-small;
    line-height: calc(#{$font-size-small} * 0.7);
    text-transform: uppercase;
    font-weight: $font-weight-bold;
  }
}
</style>
