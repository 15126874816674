<template>
  <div class="chart-wrap">
    <canvas id="myChart" width="750" height="250"></canvas>
    <div class="title">{{ net.coin.toUpperCase() }} to USD Price Chart</div>
  </div>
</template>

<script>
import Chart from 'chart.js';
import { nextTick, computed } from 'vue';

export default {
  name: 'Graph',
  props: {
    data: {
      type: Object,
    },
    net: {
      type: Object,
      Default: '',
    },
  },
  setup(props) {
    let myChart = null;
    const label = computed(() => {
      const days = Object.keys(props.data);
      return days;
      // return days.map((day) => {
      //   if (day === '2020-01-01') return 'Jan 01'
      //   if (day === '2020-04-01') return 'Apr 01'
      //   if (day === '2020-07-01') return 'Jul 01'
      //   if (day === '2020-10-01') return 'Oct 01'
      //   return ''
      // });
    });
    const data = computed(() => Object.values(props.data));
    nextTick(() => {
      const ctx = document.getElementById('myChart').getContext('2d');
      myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: label.value,
          datasets: [
            {
              label: null,
              data: data.value,
              fill: false,
              pointRadius: 0,
              borderWidth: 2,
              borderColor: '#FFC40D',
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          tooltips: {
            enabled: false,
          },
          scales: {
            yAxes: [
              {
                position: 'right',

                gridLines: {
                  zeroLineColor: '#7287D7',
                  color: '#2E4187',
                  drawBorder: false,
                },
                ticks: {
                  beginAtZero: true,
                  padding: 10,
                  // stepSize: 16
                  fontColor: '#FFFFFF',
                },
              },
            ],
            xAxes: [
              {
                type: 'time',
                time: {
                  unit: 'month',
                  unitStepSize: 3,
                },
                ticks: {
                  beginAtZero: true,
                  fontColor: '#F15B22',
                },
                // zeroLineColor: '#000000',
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        },
      });
    });

    return { myChart };
  },
};
</script>

<style lang="scss" scoped>
.chart-wrap {
  position: relative;
}
.title {
  position: absolute;
  top: 0;
  font-size: $font-size-medium-2;
  font-weight: $font-weight-regular2;
  padding: 10px;
  background: $color-primary-bg;
}
</style>
