<template>
  <div class="range__wrap">
    <input
      type="range"
      ref="input"
      :min="min"
      :max="max"
      :value="modelValue"
      class="range__slider"
      @input="onInput($event.target.value)"
    />
  </div>
</template>

<script>
import { ref, nextTick } from 'vue';

export default {
  name: 'Buy',
  props: {
    min: {
      type: String,
      default: '0',
    },
    max: {
      type: String,
      default: '100',
    },
    modelValue: {
      type: [Number, String],
      default: 50,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const input = ref(null);

    const setProgressColor = () => {
      const progress =
        ((input.value.value - input.value.min) /
          (input.value.max - input.value.min)) *
        100;
      input.value.style.background =
        'linear-gradient(to right, #FFC40D 0%, #FFC40D ' +
        progress +
        '%, #603CEE ' +
        progress +
        '%, #603CEE 100%)';
    };

    const onInput = (value) => {
      emit('update:modelValue', value);
      setProgressColor();
    };

    nextTick(() => setProgressColor());

    return { input, onInput };
  },
};
</script>

<style lang="scss" scoped>
.range {
  &__slider {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    height: 6px;
    outline: none;
    background: $color-second;
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 35px;
      height: 35px;
      background: $color-primary-text;
      border: 5px solid $color-third;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}
</style>
