<template>
  <div class="buy__wrap">
    <div class="buy__title">Buy {{ net.coin.toUpperCase() }}</div>
    <div class="buy__text">
      {{ net.name.toUpperCase() }} is listed on multiple exchanges and can be
      bought for both cryptocurencies and fiat
    </div>
    <div class="buy__metrics">
      <Metrics :data="metrics" spot />
    </div>
    <div class="buy__graph" v-if="graphData">
      <!-- <img src="~@/assets/images/graph.svg" /> -->
      <Graph :net="net" :data="graphData"/>
    </div>
  </div>
</template>

<script>
import Metrics from '@/components/additional/Metrics.vue';
import Graph from '@/components/additional/Graph.vue';

import { useStore } from 'vuex';
import { computed } from 'vue';
import { currencyFormatter } from '@/composition/currencyFormatter'

export default {
  name: 'Buy',
  components: { Metrics, Graph },
  props: {
    data: {
      type: Object,
    },
    net: {
      type: Object,
      Default: '',
    },
  },
  setup(props) {
    const store = useStore();

    const metrics = computed(() => {
      const marketcap =
        store.getters.GET_MARKETCAP?.[props.net.api];
      if (!marketcap) return;
      if (props.net.api === 'secret') {
        marketcap.marketCap = 91944603
      }
      return [
        {
          name: 'Price, $',
          value: (+marketcap.priceUsd).toFixed(2),
          unit: '$',
          unitPosition: 'before',
        },
        {
          name: 'Price, BTC',
          value: marketcap.priceBtc,
          unit: 'BTC',
          unitPosition: 'after',
        },
        {
          name: 'Market cap',
          value: currencyFormatter(marketcap.marketCap),
          unit: '$',
          unitPosition: 'before',
        },
      ];
    });

    const graphData = computed(() => {
      return store.getters.GET_GRAPH_DATA
    })
    return { metrics, graphData };
  },


};
</script>

<style lang="scss" scoped>
.buy {
  &__wrap {
    position: relative;
    text-align: left;
  }
  &__title {
    color: $color-first;
    font-size: $font-size-title-3;
    font-weight: $font-weight-bold;
    margin-bottom: 39px;
    @media (max-width: 767px) {
      text-align: center;
      font-size: $font-size-medium;
      margin-bottom: 15px;
    }
  }
  &__text {
    margin-bottom: 54px;
    @media (max-width: 767px) {
      text-align: center;
      margin-bottom: 28px;
    }
  }
  &__graph {
    position: absolute;
    z-index: 2;
    bottom: 55px;
    right: 30px;
    @media (max-width: 1199px) {
      display: none;
    }
  }
}
</style>
