<template>
  <div class="calc__wrap">
    <div class="calc__header">
      <div class="header__amount">
        <div class="amount__title">
          How many <span>{{ net.coin.toUpperCase() }}</span> do you have?
        </div>
        <div class="amount__value">
          <span>{{ currencyFormatter(currentUsdValue / rateUSD) }}</span>
          {{ net.coin.toUpperCase() }}
        </div>
        <div class="amount__usd">
          $ <span>{{ currencyFormatter(currentUsdValue) }}</span>
        </div>
      </div>
      <div class="header__percent">
        <div class="percent__title">Yearly Return</div>
        <div class="percent__value">
          +{{ Math.round(yearlyReturn * 10) / 10 }}%
        </div>
      </div>
    </div>
    <div class="calc__range"><RangeSlider v-model="rangeValue" /></div>
    <div class="calc__results">
      <ResultsRow
        :net="net.coin.toUpperCase()"
        title="Daily earnings"
        :value-net="currencyFormatter(yearlyEarningsUsd / rateUSD / 365, 2)"
        :value-usd="currencyFormatter(yearlyEarningsUsd / 365, 2)"
      />
      <ResultsRow
        :net="net.coin.toUpperCase()"
        title="Monthly earning"
        :value-net="currencyFormatter(yearlyEarningsUsd / rateUSD / 12, 2)"
        :value-usd="currencyFormatter(yearlyEarningsUsd / 12, 2)"
      />
      <ResultsRow
        :net="net.coin.toUpperCase()"
        title="Yearly earning"
        :value-net="currencyFormatter(yearlyEarningsUsd / rateUSD, 2)"
        :value-usd="currencyFormatter(yearlyEarningsUsd, 2)"
      />
    </div>
  </div>
</template>

<script>
import RangeSlider from '@/components/calcs/elements/RangeSlider.vue';
import ResultsRow from '@/components/calcs/elements/ResultsRow.vue';

import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import { currencyFormatter } from '@/composition/currencyFormatter'

export default {
  name: 'NetCalc',
  components: { RangeSlider, ResultsRow },
  props: {
    data: {
      type: Object,
    },
    net: {
      type: Object,
      Default: '',
    },
  },
  setup(props) {
    const store = useStore();

    const rangeValue = ref(50);

    const rateUSD = computed(
      () => store.getters.GET_CURRENCY?.[props.net.api]?.rates.USD
    );

    const currentUsdValue = computed(() => {
      if (+rangeValue.value === 0) {
        return 0
      }
      return 10 ** ((6 * rangeValue.value) / 100);
    });

    const currentNetValue = computed(
      () => currentUsdValue.value / rateUSD.value
    );

    const rewards =
      props.data.metrics.find((metric) => metric.name === 'Rewards')
        .value / 100;

    const yearlyReturn = computed(() => {
      // const yearlyRangesNum = 12;
      // return ((1 + rewards / yearlyRangesNum) ** yearlyRangesNum - 1) * 100;
      return rewards * 100
    });

    const yearlyEarningsUsd = computed(
      () => (currentUsdValue.value * yearlyReturn.value) / 100
    );

    return {
      rangeValue,
      rateUSD,
      yearlyReturn,
      currentUsdValue,
      currentNetValue,
      yearlyEarningsUsd,
      currencyFormatter
    };
  },
};
</script>

<style lang="scss" scoped>
.calc {
  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 25px;
    @media (max-width: 520px) {
      display: block;
      margin-bottom: 17px;
    }
  }
  &__range {
    margin-bottom: 22px;
  }
}

.header {
  &__amount {
    @media (max-width: 520px) {
      margin-bottom: 17px;
    }
  }
}

.amount {
  &__title {
    font-size: $font-size-normal3;
    font-weight: $font-weight-regular2;
    margin-bottom: 13px;
    & span {
      color: $color-first;
    }
    @media (max-width: 520px) {
      font-size: $font-size-small;
      margin-bottom: 6px;
    }
  }
  &__value {
    font-size: $font-size-title-6;
    font-weight: $font-weight-regular;
    margin-bottom: 7px;
    & span {
      font-weight: $font-weight-bold;
    }
    @media (max-width: 520px) {
      font-size: $font-size-small;
    }
  }
  &__usd {
    font-size: $font-size-normal;
    font-weight: $font-weight-regular;
    color: $color-first;
    & span {
      font-weight: $font-weight-bold;
    }
    @media (max-width: 520px) {
      font-size: $font-size-medium;
    }
  }
}
.percent {
  &__title {
    font-weight: $font-weight-regular2;
    margin-bottom: 13px;
    @media (max-width: 520px) {
      margin-bottom: 4px;
    }
  }
  &__value {
    font-size: $font-size-title-6;
    color: $color-first;
    @media (max-width: 520px) {
      font-size: $font-size-medium;
    }
  }
}
</style>
