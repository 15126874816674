<template>
  <div class="where__wrap">
    <div class="where__title">
      Where?
    </div>
    <div class="where__citadel" v-if="data.isCitadel">
      <div class="citadel__container">
        <div class="citadel__header">
          <img src="~@/assets/images/decor/citadel.svg" class="citadel__icon" />
          <div class="citadel__title">
            Citadel.one
          </div>
        </div>
        <div class="citadel__text">
          Citadel.one provides users with an integrated fiat on-ramp, they can
          buy and sell crypto with a credit or debit card
        </div>
      </div>
      <div class="citadel__button">
        <Button color="white" href="https://app.citadel.one/">buy now</Button>
      </div>
    </div>
    <div class="where__container">
      <a
        class="where__card card"
        :href="exchange.link" target="_blank"
        v-for="(exchange, index) in data.otherExchanges"
        :key="index"
      >
        <img
          class="image"
          :src="require(`@/assets/images/exchanges/${exchange.name}_logo.png`)"
          alt=""
        />
        <img
          class="image_hover"
          :src="require(`@/assets/images/exchanges/${exchange.name}_logo_hover.png`)"
          alt=""
        />
        <!-- <svg height="65%">
          <use
            :xlink:href="
              require(`@/assets/images/exchanges/${exchange}.svg`) +
                `#${exchange}`
            "
          ></use>
        </svg> -->
      </a>
    </div>
  </div>
</template>

<script>
import Button from '@/components/additional/Button.vue';

export default {
  name: 'Where',
  components: { Button },
  props: {
    data: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.where {
  &__wrap {
    text-align: left;
  }
  &__title {
    font-weight: $font-weight-bold;
    font-size: $font-size-title-2;
    margin-bottom: 48px;
    @media (max-width: 767px) {
      margin-bottom: 38px;
      text-align: center;
      font-size: $font-size-medium-2;
    }
  }
  &__citadel {
    display: flex;
    flex-wrap: wrap;
    align-items: ceter;
    justify-content: space-between;
    // height: 120px;
    border-radius: 6px;
    background: $color-first;
    margin-bottom: 24px;
    padding: 35px 35px 30px;
    @media (max-width: 1010px) {
      display: block;
      max-width: 745px;
      margin: 0 auto 24px;
    }
    @media (max-width: 817px) {
      max-width: 602px;
    }
  }
  &__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: 654px) {
      display: block;
    }
  }
  &__card {
    @media (max-width: 767px) {
      max-width: 290px;
    }
    & img {
      display: block;
      margin: 0 auto;
      @media (max-width: 817px) {
        height: 65%;
      }
    }
    .image_hover {
      display: none;
    }
    &:hover {
      .image {
        display: none;
      }
      .image_hover {
        display: block;
      }
    }
  }
}

.citadel {
  &__header {
    display: flex;
    align-items: center;
    margin-right: 44px;
    @media (max-width: 1010px) {
      margin-bottom: 20px;
    }
  }
  &__container {
    display: flex;
    align-items: center;
    @media (max-width: 1010px) {
      display: block;
    }
    // margin: 0 -22px -24px;
  }
  &__icon {
    margin-right: 22px;
  }
  &__title {
    font-weight: $font-weight-bold;
    font-size: $font-size-large;
  }
  &__text {
    max-width: 482px;
    @media (max-width: 1010px) {
      margin-bottom: 19px;
      max-width: 100%;
    }
  }
}

.card {
  height: 120px;
  border-radius: 6px;
  background: $color-seventh;
  width: 350px;
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-right: 45px;
  }
  @media (max-width: 1199px) {
    &:not(:last-child) {
      margin: 0 22px 24px;
    }
    margin: 0 22px 24px;
  }

  @media (max-width: 817px) {
    width: 290px;
    height: 70px;
    &:not(:last-child) {
      margin: 0 11px 22px;
    }
    margin: 0 11px 22px;
    @media (max-width: 654px) {
      width: 100%;
      margin: 0 auto 22px;
      &:not(:last-child) {
        margin: 0 auto 22px;
      }
      // margin: 0 0 22px;
    }
  }

  cursor: pointer;
  & svg {
    fill: $color-second;
    display: block;
    margin: 0 auto;
  }
  &:hover {
    background: $color-fourth;
    & svg {
      fill: white;
    }
  }
}
</style>
