<template>
  <button
    v-if="href === '#'"
    :class="`button_${color} button_${size} ${adaptive && 'button_adaptive'}`"
    class="button"
  >
    <img
      v-if="icon"
      class="button__image"
      :src="require(`@/assets/images/butt_icon/${icon}.svg`)"
      alt=""
    />
    <slot />
  </button>

  <a
    v-else
    :href="href"
    :class="`button_${color} button_${size} ${adaptive && 'button_adaptive'}`"
    :style="`width: ${width}px`"
    class="button"
    target="_blank"
  >
    <img
      v-if="icon"
      class="button__image"
      :src="require(`@/assets/images/butt_icon/${icon}.svg`)"
      alt=""
    />
    <slot />
  </a>
</template>

<script>
export default {
  name: 'Button',
  props: {
    color: {
      type: String,
      validator: function(value) {
        return ['blue', 'orange', 'white', 'disabled'].indexOf(value) !== -1;
      },
      default: 'blue',
    },
    size: {
      type: String,
      default: 'small',
    },
    adaptive: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '#',
    },
    width: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  text-transform: uppercase;
  border-radius: 6px;
  font-weight: $font-weight-bold;
  font-size: $font-size-small;
  cursor: pointer;
  transition: $transition;
  &:hover {
    opacity: $opacity-button;
  }

  &_small {
    min-height: 50px;
    width: 175px;
  }

  &_medium {
    min-height: 60px;
    min-width: 230px;
  }

  &_adaptive {
    min-width: auto;
  }

  &_disabled {
    color: white;
    background-color: $color-sixth;
    cursor: auto;
    &:hover {
      opacity: 1;
    }
  }

  &_blue {
    color: white;
    background-color: $color-second;
  }

  &_orange {
    color: white;
    background-color: $color-first;
  }

  &_white {
    color: $color-second;
    background-color: white;
  }

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }

  &__image {
    margin-right: 7px;
    margin-top: -3px;
  }
}
</style>
