export default {
	state: {
    cosmosConfig: {
      net: {
        name: 'Cosmos',
        secondName: 'Cosmos',
        coin: 'atom',
        api: 'cosmos'
      },
      about: {
        icon: require('@/assets/images/nets/cosmos/icon.svg'),
        title: 'cosmos',
        socials: [
          {
            name: 'cosmos.network',
            title: 'cosmos.network',
            icon: require('@/assets/images/web-icon.svg'),
            href: 'https://cosmos.network/'
          },
          {
            name: 'twitter',
            title: '@cosmos',
            icon: require('@/assets/images/twitter-icon.svg'),
            href: 'https://twitter.com/cosmos'
          }
        ],
        descryption: `
        Cosmos Network tries to solve some of the <b>“hardest problems”</b> the blockchain industry faces. <b>Scalability</b> - Tendermint BFT is a byzantine fault-tolerant consensus engine that powers Cosmos Proof-of-Stake instead of slow, expensive, unscalable, and environmentally harmful Proof-of-Work protocols. <b>Complexity</b> - Cosmos SDK is a modular framework for building interoperable, application-specific blockchains that makes interactions with blockchain for developers less complicated and difficult. <b>Interoperability</b> - Interblockchain Communication protocol makes it easier for blockchain networks to communicate with each other — preventing fragmentation in the industry.
        `
      },
      buy: {},
      where: {
        isCitadel: true,
        otherExchanges: [
          {
            name: 'binance',
            link: 'https://www.binance.com/en/trade/ATOM_USDT'
          },
          {
            name: 'okex',
            link: 'https://www.okex.com/market?product=atom_usdt'
          },
          {
            name: 'upbit',
            link: 'https://pro.coinbase.com/trade/ATOM-USD'
          }
        ]
      },
      stake: {
        calc: {},
        metrics: [
          {
            name: 'Rewards',
            value: '9.30',
            unit: '%',
            unitPosition: 'after'
          },
          {
            name: 'Inflation',
            value: '7',
            unit: '%',
            unitPosition: 'after'
          },
          {
            name: 'Staking ratio',
            value: '67.33 ',
            unit: '%',
            unitPosition: 'after'
          }
        ],
      },
      how: {
        video: 'https://www.youtube.com/embed/UIotQ9-SEUo'
      },
      faqs: [
        {
          question: 'What are validator commissions?',
          answer: 'This is the commission that a validator of your choice charges for performing their validation service. Generally validator costs to maintain high-end infrastructure are relatively high and the validator has to cover operating expenses and provide a strong infrastructure.'
        },
        {
          question: 'What are thew risks of delegating?',
          answer: 'Validators may be slashed for 2 types of misbehaviour. Soft slashing can happen when a validator has a long period of node downtime (less than ~18 out of 19 hours). As a consequence both the delegator and validator lose 0,01% of their total assets staked and the validator would be jailed for 10 minutes. Hard slashing can happen when a validator double signs a block which would also penalize both the validator and delegator for a total 5% slash on the assets that are staked. All delegators of this validator enter the unbonding period. In order to prevent this, delegators should research their validators and their infrustructure.'
        },
        {
          question: 'Is there an unbonding period?',
          answer: 'Yes, unbonding period set by Cosmos Network is 21 days.'
        }
      ],
      learn: {
        title: 'Learn more',
        posts: [
          {
            title: 'Citadel.one Staking Guide: Cosmos ($ATOM)',
            img: 'https://miro.medium.com/max/1280/0*fN8qAKikEw4Q-GSq',
            href: 'https://medium.com/citadel-one/citadel-staking-guide-cosmos-atom-9585b3f5e169',
            description: 'To get started, go on an official website Citadel.one and log in'
          },
          {
            title: 'Guide: Ledger Support for Cosmos',
            img: 'https://miro.medium.com/max/1280/1*DWOdgnpLKR5tw8xCfOiAIw.png',
            href: 'https://medium.com/citadel-one/guide-ledger-support-for-cosmos-dd71b4f9437e',
            description: 'Hello, guys! Recently, $ATOM was integrated into our platform, introducing a great variety...'
          },
          {
            title: 'Citadel.one Overview: Cøsmos ($ATOM)',
            img: 'https://miro.medium.com/max/1280/1*OJMD4DK0IB00VqQBpj8VJA.png',
            href: 'https://medium.com/citadel-one/citadel-overview-c%C3%B8smos-atom-24f384227cf5',
            description: 'Cosmos ($ATOM) Is Now Integrated To Citadel.one. Here is the overview of all features available for ATOM holders'
          },
          {
            title: 'Citadel’s Features for $ATOM Holders',
            img: 'https://miro.medium.com/max/1280/1*_bx7yzrRvBECQazQZOyRCQ.png',
            href: 'https://medium.com/citadel-one/citadels-features-for-atom-holders-e98da2b1879',
            description: 'This is the 5th Proof-of-Stake network to be integrated into Citadel.one!'
          }
        ]
      },
    },
  },
	getters: {
    GET_COSMOS_CONFIG(state) {
      return state.cosmosConfig
    },
    GET_COSMOS_POSTS(state) {
      return state.cosmosConfig.learn.posts
    },
    GET_COSMOS_REWARDS(state) {
      return state.cosmosConfig.stake.metrics[0].value
    }
  },
  mutations: {
    SET_COSMOS_STAKE_METRIC(state, { name, value }) {
      const index = state.cosmosConfig.stake.metrics.findIndex(
        (metric) => metric.name === name
      );
      return (state.cosmosConfig.stake.metrics[index].value = value);
    }
  },
  actions: {
    UPDATE_COSMOS_STAKE_METRICS({ rootGetters, commit }, metrics) {
      metrics.map((metric) => {
        const newValue = +rootGetters[`GET_MARKETCAP`].cosmos?.[metric.key];
        if (newValue && !Number.isNaN(newValue)) {
          commit('SET_COSMOS_STAKE_METRIC', {
            name: metric.name,
            value: newValue.toFixed(1),
          });
        }
      });
    },
  },
}
