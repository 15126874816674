import { createRouter, createWebHistory } from 'vue-router'
import App from '../App.vue'

const routes = [

  // {
  //   path: '/iost',
  //   name: 'iost',
  //   component: App,
  // },
  // {
  //   path: '/cosmos',
  //   name: 'cosmos',
  //   component: App,
  // },
  // {
  //   path: '/secret',
  //   name: 'secret',
  //   component: App,
  // },
  // {
  //   path: '/band',
  //   name: 'band',
  //   component: App,
  // },
  // {
  //   path: '/',
  //   redirect: { name: 'iost' }
  // },
  {
    path: '/',
    name: 'root',
    component: App,
  },
  {
    path: '/:some', 
    redirect: '/'
  },
  // {
  //   path: '/:net', 
  //   redirect: (to) => {
  //   console.log(to)
    // if ([...nets].includes(to)) {
    //   return
    // } else {
    //   return 'iost'
    // }

  //   return 'iost'
  //   }
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return {
      top: 0
    }
  },
  routes
})

export default router
