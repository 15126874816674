<template>
  <div class="wrap">
    <div class="metrics">
      <div class="metrics__title">
        metrics
      </div>
      <div class="metrics__table">
        <tr v-for="(metric, index) in data" :key="index" class="table__row">
          <td class="table__icon">
            <img src="@/assets/images/decor/metric-arrow.svg" />
          </td>
          <td class="table__name">{{ metric.name }}</td>
          <td class="table__value">
            <span v-if="metric.unitPosition === 'before'">{{
              metric.unit
            }}</span>
            {{ metric.value }}
            <span v-if="metric.unitPosition === 'after'">{{
              metric.unit
            }}</span>
          </td>
        </tr>
      </div>
    </div>
    <div class="spot" v-if="spot"></div>
    <div class="center-spot"></div>
  </div>
</template>

<script>
export default {
  name: 'Metrics',
  props: {
    data: {
      type: Object,
    },
    spot: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  position: relative;
  width: 100%;
  height: 385px;
  border: 10px solid $color-second;
  @media (max-width: 1199px) {
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    height: 330px;
  }
  @media (max-width: 767px) {
    height: 195px;
    border-color: $color-eighth;
  }
}

.metrics {
  position: relative;
  margin-left: -10px;
  background: $color-primary-bg;
  padding: 60px 0 33px;
  @media (max-width: 1199px) {
    display: inline-block;
    margin-left: auto;
  }
  @media (max-width: 767px) {
    padding: 23px 0 0;
  }
  &__title {
    text-align: left;
    text-transform: uppercase;
    font-size: $font-size-medium-2;
    font-weight: $font-weight-bold;
    margin-bottom: 30px;
    @media (max-width: 1199px) {
      text-align: center;
    }
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }
  &__table {
    text-align: left;
  }
}

.spot {
  position: absolute;
  right: -10px;
  top: 87px;
  height: 190px;
  width: 20px;
  background: $color-primary-bg;
  @media (max-width: 1199px) {
    display: none;
  }
}

.center-spot {
  display: none;
  position: absolute;
  background: $color-primary-bg;
  width: 120px;
  height: 20px;
  top: -10px;
  left: calc(50% - 60px);
  @media (max-width: 1199px) {
    display: block;
  }
}

.table {
  &__row {
    height: 40px;
    font-size: $font-size-medium;
    @media (max-width: 767px) {
      font-size: $font-size-small;
      height: 33px;
    }
  }
  &__icon {
    padding-right: 15px;
    @media (max-width: 767px) {
      padding-right: 5px;
    }
  }
  &__name {
    padding-right: 22px;
    font-weight: $font-weight-regular2;
    @media (max-width: 767px) {
      padding-right: 10px;
    }
  }
  &__value {
    font-weight: $font-weight-regular2;
    color: $color-third;
  }
}
</style>
