<template>
  <div class="title__wrap" :class="`title_${position}`">
    <div class="title__spot" :class="`title__spot_${position}`"></div>
    <div class="title__text">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'Title',
  components: {},
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    position: {
      type: String,
      validator: function(value) {
        return ['left', 'right'].indexOf(value) !== -1;
      },
      default: 'right',
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  &_right {
    margin-left: auto;
    text-align: right;
    @media (max-width: 767px) {
      margin: 0 auto;
      text-align: center;
    }
  }
  &_left {
    margin-right: auto;
    text-align: left;
    @media (max-width: 767px) {
      margin: 0 auto;
      text-align: center;
    }
  }
  &__spot {
    margin-bottom: 32px;
    width: 100px;
    height: 10px;
    background-color: $color-first;
    @media (max-width: 767px) {
      height: 7px;
      margin: 0 auto 19px;
    }
    &_right {
      margin-left: auto;
      // background-color: #f15b22;
    }
    // &_left {
      // background-color: #ffc40d;
      // @media (max-width: 767px) {
      //   margin: 0 auto 7px;
      // }
    // }
  }
  &__text {
    font-weight: $font-weight-bold;
    font-size: $font-size-title-4;
    margin-bottom: 5px;
    @media (max-width: 767px) {
      font-size: 23px;
      line-height: 28px;
      margin-bottom: 10px;
    }
  }
}
</style>
