<template>
  <div class="stake__wrap">
    <div class="stake__title">Stake {{ net.coin.toUpperCase() }} to earn passive income</div>
    <div class="stake__description">
      Staking involves holding funds in a cryptocurrency wallet to support the
      security and operations of a blockchain network. Simply put, staking is
      the act of locking cryptocurrencies to receive passive income.
    </div>
    <div class="stake__content">
      <div class="stake__metrics">
        <Metrics :data="data.metrics" />
      </div>
      <div class="stake__calc" v-if="data">
        <NetCalc :data="data" :net="net" />
        <!-- <IostCalc v-if="net.toLowerCase() === 'iost'" :data="data.calc" />
        <CosmosCalc v-if="net.toLowerCase() === 'cosmos'" :data="data.calc" />
        <SecretCalc v-if="net.toLowerCase() === 'secret'" :data="data.calc" />
        <BandCalc v-if="net.toLowerCase() === 'band'" :data="data.calc" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import NetCalc from '@/components/calcs/NetCalc.vue';
// import IostCalc from '@/components/calcs/IostCalc.vue';
// import CosmosCalc from '@/components/calcs/CosmosCalc.vue';
// import SecretCalc from '@/components/calcs/SecretCalc.vue';
// import BandCalc from '@/components/calcs/BandCalc.vue';

import Metrics from '@/components/additional/Metrics.vue';

export default {
  name: 'Stake',
  components: {
    Metrics,
    // IostCalc,
    // CosmosCalc,
    // SecretCalc,
    // BandCalc,
    NetCalc,
  },
  props: {
    data: {
      type: Object,
    },
    net: {
      type: Object,
      Default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.stake {
  &__wrap {
    text-align: left;
  }
  &__title {
    color: $color-first;
    font-size: $font-size-title-3;
    font-weight: $font-weight-bold;
    margin-bottom: 31px;
    @media (max-width: 767px) {
      font-size: $font-size-medium;
      text-align: center;
      margin-bottom: 25px;
    }
  }
  &__description {
    margin-bottom: 99px;
    @media (max-width: 767px) {
      margin-bottom: 24px;
    }
  }
  &__content {
    position: relative;
  }
  &__metrics {
    @media (max-width: 1199px) {
      margin-bottom: 30px;
    }
  }
  &__calc {
    position: absolute;
    z-index: 2;
    padding: 43px 45px 63px 54px;
    top: -58px;
    right: 147px;
    max-width: 565px;
    min-height: 500px;
    background: $color-primary-bg;
    box-shadow: 55px 65px 44px 0px rgba(21, 8, 75, 0.4),
      20px 30px 30px 0px rgba(21, 8, 75, 0.15),
      10px 20px 20px 0px rgba(21, 8, 75, 0.15),
      1px 10px 10px 0px rgba(21, 8, 75, 0.1),
      0px -20px 40px 0px rgba(21, 8, 75, 0.25);
    border-radius: 12px;
    @media (max-width: 1199px) {
      position: static;
      margin: 0 auto;
    }
    @media (max-width: 520px) {
      padding: 26px 22px 24px;
    }
  }
}
</style>
