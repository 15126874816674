<template>
  <div class="how__wrap">
    <div class="how__title">How?</div>
    <div class="how__content">
      <div class="how__citadel">
        <div class="citadel__header">
          <img src="~@/assets/images/decor/citadel.svg" class="citadel__icon" />
          <div class="citadel__title">
            Citadel.one
          </div>
        </div>
        <div class="how__text">
          Citadel.one is the next generation non-custodial crypto-asset
          management platform addressing changes and challenges in staking and
          DeFi markets. It offers a unified, user-friendly experience across
          multiple networks on both web and mobile.
        </div>
        <div class="how__button">
          <Button color="white" href="https://app.citadel.one/" width="175" >stake</Button>
        </div>
      </div>

      <div class="how__video">
        <iframe
          width="100%"
          height="100%"
          :src="data.video"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/additional/Button.vue';

export default {
  name: 'How',
  components: { Button },
  props: {
    data: {
      type: Object,
    }
  },
};
</script>

<style lang="scss" scoped>
.how {
  &__wrap {
    text-align: left;
  }
  &__title {
    font-weight: $font-weight-bold;
    font-size: $font-size-title-2;
    margin-bottom: 48px;
    @media (max-width: 767px) {
      font-size: $font-size-medium-2;
      text-align: center;
      margin-bottom: 27px;
    }
  }
  &__content {
    display: flex;
    height: 417px;
    @media (max-width: 767px) {
      display: block;
      height: auto;
    }
  }
  &__citadel {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    padding: 34px 19px 33px 35px;
    height: 100%;
    width: 350px;
    background: $color-first;
    margin-right: 38px;
    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 24px;
    }
  }
  &__text {
    flex: 1 1 100%;
    margin-bottom: 20px;
    // text-align: left;
  }
  &__video {
    height: 100%;
    flex: 1 1 auto;
    border-radius: 6px;
    overflow: hidden;
    @media (max-width: 767px) {
      // max-width: 350px;
      // height: auto;
      text-align: center;
      position: relative;
      padding-bottom: 56.25%;
      overflow: hidden;
      & iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        border-width: 0;
        outline-width: 0;
      }
    }
  }
}

.citadel {
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
  }
  &__icon {
    margin-right: 22px;
  }
  &__title {
    font-weight: $font-weight-bold;
    font-size: $font-size-large;
  }
}
</style>
