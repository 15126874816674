<template>
  <div class="what-to-do__wrap">
    <div class="what-to-do__title">
      <Title :title="`What to do with ${net.name}`" position="left" />
    </div>
    <slot />
    <div class="what-to-do__text">
      <div class="what-to-do__text__big">
        Buy, send, stake <span>{{ net.name }}</span> on the go
      </div>
      <div class="what-to-do__text__small">
        Enjoy all-in-one platform for management and staking of
        {{ net.name.toUpperCase() }} and other crypto assets in your hand!
      </div>
    </div>
    <div class="what-to-do__buttons">
      <Button
        color="orange"
        icon="apple"
        href="https://apps.apple.com/us/app/citadel-one/id1546701475?ign-mpt=uo%3D2"
        >App store</Button
      >
      <Button
        icon="google"
        href="https://play.google.com/store/apps/details?id=one.citadel.mobile"
        >google play</Button
      >
    </div>
  </div>
</template>

<script>
import Title from '@/components/additional/Title.vue';
import Button from '@/components/additional/Button.vue';

export default {
  name: 'WhatToDo',
  components: { Title, Button },
  props: {
    net: {
      type: Object,
      Default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.what-to-do {
  &__wrap {
    text-align: center;
  }
  &__title {
    margin-bottom: 84px;
    @media (max-width: 767px) {
      margin-bottom: 27px;
    }
  }
  &__text {
    margin-top: 88px;
    margin-bottom: 45px;
    @media (max-width: 767px) {
      margin-bottom: 23px;
      margin-top: 49px;
    }
    & span {
      color: $color-third;
    }
    &__big {
      font-weight: $font-weight-bold;
      font-size: $font-size-title-5;
      margin-bottom: 48px;
      line-height: $line-height-title;
      @media (max-width: 767px) {
        font-size: $font-size-large;
        line-height: $line-height-title-mobile;
        margin-bottom: 21px;
      }
    }
    &__small {
      margin: 0 auto;
      max-width: 500px;
      font-size: $font-size-normal;
      @media (max-width: 767px) {
        font-size: $font-size-small;
      }
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    @media (max-width: 500px) {
      display: block;
    }
    & a {
      @media (max-width: 500px) {
        margin: 0 auto;
      }
    }
    & a:not(:last-child) {
      margin-right: 30px;
      @media (max-width: 500px) {
        margin: 0 auto 16px;
      }
    }
  }
}
</style>
