<template>
  <div class="learn__wrap">
    <div class="learn__title">
      <Title :title="data.title" position="left" />
    </div>
    <div class="learn__content">
      <div v-for="(post, key) in posts" class="post" :key="key">
        <a :href="post.href" target="_blank">
          <div class="post__link">
            <div class="post__image">
              <img :src="post.img" alt="" />
            </div>
            <div class="post__title">
              {{ post.title }}
            </div>
            <div class="post__description">
              {{ post.description }}
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/additional/Title.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name: 'Learn',
  components: {
    Title,
  },
  props: {
    data: {
      type: Object,
    },
    net: {
      type: Object,
    },
  },
  setup(props) {
    const store = useStore();

    const posts = computed(() => {
      let posts = store.getters[`GET_${ props.net.name.toUpperCase() }_POSTS`];
      posts && (posts = posts.splice(0, 3));
      return posts;
    });
    return { posts };
  },
};
</script>

<style lang="scss" scoped>
.learn {
  &__title {
    margin-bottom: 68px;
    @media (max-width: 767px) {
      margin-bottom: 35px;
    }
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    margin-bottom: 34px;
    height: 342px;
    overflow: hidden;
    @media (max-width: 1199px) {
      margin-bottom: 24px;
    }
    @media (max-width: 1023px) {
      grid-template-columns: 1fr 1fr;
      max-width: 700px;
      margin: 0 auto 24px;
    }
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      margin-bottom: 0px;
      max-width: 315px;
      height: 330px;
    }
  }
}

.post {
  text-align: left;
  margin-bottom: 30px;
  position: relative;
  @media (max-width: 767px) {
    margin: 0 auto 25px;
  }
  &__link {
    position: relative;
    display: block;
    transition: $transition;
    &:hover {
      cursor: pointer;
      opacity: $opacity;
      transition: $transition;
    }
  }
  &__image {
    display: inline-block;
    margin-bottom: 19px;
    width: 100%;
    @media (max-width: 767px) {
      margin: 0 auto 16px;
      max-width: 315px;
    }
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      box-shadow: 0px 0px 25px 0px rgba(52, 29, 143, 0.15);
    }
  }
  &__title {
    text-align: left;
    margin-bottom: 9px;
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-normal;
    line-height: $line-height-posts;
    @media (max-width: 767px) {
      margin: 0 auto 9px;
    }
  }
  &__description {
    text-align: left;
    font-weight: $font-weight-thin;
    font-size: $font-size-small;
    line-height: $line-height-posts;
    color: $color-secondary-text;
  }
}
</style>
