<template>
  <div class="header__title">
    <a href="https://citadel.one/"> Citadel.one</a>
  </div>
</template>

<script>
export default {
  name: 'Header',
};
</script>

<style lang="scss" scoped>
.header__title {
  font-weight: 700;
  font-size: $font-size-large;
}
</style>
