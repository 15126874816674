import { createStore } from 'vuex'
import axios from 'axios';

import iost from './modules/iost'
import cosmos from './modules/cosmos'
import secret from './modules/secret'
import band from './modules/band'

const api = process.env.VUE_APP_BACKEND_URL;
// const dashboard = process.env.VUE_APP_DASHBOARD_URL;

export default createStore({
  modules: { 
    iost,
    band,
    secret,
    cosmos
	},
  state: {
    networks: ['iost', 'cosmos', 'secret', 'band'],
    posts: null,
    currency: null,
    marketcap: null,
    graphData: null,
  },
  
  getters: {
    GET_NETWORKS(state) {
      return state.networks
    },
    GET_CURRENCY(state) {
      return state.currency
    },
    GET_MARKETCAP(state) {
      return state.marketcap
    },
    GET_GRAPH_DATA(state) {
      return state.graphData
    },
  },

  mutations: {
    SET_CURRENCY(state, payload) {
      return (state.currency = payload)
    },
    SET_MARKETCAP(state, payload) {
      return (state.marketcap = payload)
    },
    SET_GRAPH_DATA(state, payload) {
      return (state.graphData = payload)
    },
  },

  actions: {
    async PULL_CURRENCY({commit}) {
      try {
        const { data } = await axios.get(`${api}/currency`);
        if (!data.ok) {
          return
        }
        commit('SET_CURRENCY', data.data);

        } catch(error) {console.log(error)}
    },
    async PULL_MARKETCAP({commit}) {
      try {
        const { data } = await axios.get(`${api}/currency/marketcap`);
        if (!data.ok) {
          return
        }
        commit('SET_MARKETCAP', data.data);
        } catch(error) {console.log(error)}
    },
    async PULL_GRAPH_DATA({commit}, { net }) {
      try {
        const toDate = Date.now()
        const fromDate = toDate - 86400000 * 365
          const { data } = await axios.get(`${api}/currency/history`, {
          params: {
            net: net,
            fiat: 'usd',
            from_date: fromDate,
            to_date: toDate,
          }
        })
        if (!data.ok) {
          return
        }
        commit('SET_GRAPH_DATA', data.data);
      } catch (error) {
        console.error(error)
      }

    },
  }
})
